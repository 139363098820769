// @flow

import React from "react";
import {
    Layout,
    InspectionPointLayout,
    InspectionPointDossier as _InspectionPointDossier,
    withPrivateRoute,
} from "@containers";

/**
 * Home Page
 */
const InspectionPointDossier = (props: *) => (
    <Layout {...props}>
        <InspectionPointLayout>
            <_InspectionPointDossier />
        </InspectionPointLayout>
    </Layout>
);

export default withPrivateRoute(
    InspectionPointDossier,
    "/inspectiepunt/:inspectionPointId/dossier",
);
